<template>
    <article class="cta-email-card" :style="{ backgroundImage: `url('/assets/png/bg-email.png')` }">
        <div class="cta-email-card__info">
            <h3 class="cta-email-info__title">
                {{ $lang.components.ctaEMail.title }}
                <b>{{ $lang.components.ctaEMail.title_2 }}</b>
                {{ $lang.components.ctaEMail.title_3 }}
            </h3>
            <p class="cta-email-info__subtitle">
                {{ $lang.components.ctaEMail.subtitle }}
                <b>{{ $lang.components.ctaEMail.subtitle_2 }}</b>
            </p>
            <form
                class="cta-email-info__email"
                :class="{ grayscale: subscribing }"
                @submit.prevent="newSubscription"
            >
                <div class="cta-email-info__email-form">
                    <InputGeneric
                        v-model.trim="form.data.email"
                        bgcolor="light"
                        type="email"
                        class="cta-email-info__email-form-input"
                        :placeholder="$lang.components.ctaEMail.placeholder"
                        :invalid="form.error.email.length > 0"
                        :disabled="subscribing"
                        @click="form.error.email = ''"
                    >
                        {{ form.error.email }}
                    </InputGeneric>
                    <ButtonGeneric
                        ref="submit"
                        class="cta-email-info__email-form-btn"
                        :info="{
                            text: $lang.components.ctaEMail.button,
                            handler: newSubscription,
                        }"
                        :disabled="subscribing"
                    ></ButtonGeneric>
                </div>
                <div class="cta-email-info__email-checkbox">
                    <InputCheckbox
                        id="cta-privacy-checkbox"
                        v-model="form.data.agree"
                        :invalid="form.error.agree"
                        :disabled="subscribing"
                    >
                        Sí, acepto la
                        <NuxtLink no-prefetch to="/politicas-de-privacidad">Política de Privacidad</NuxtLink>
                        & los
                        <NuxtLink no-prefetch to="/aviso-legal">Términos & Condiciones</NuxtLink>
                        de {{ $lang.brand }}.
                    </InputCheckbox>
                </div>
            </form>
        </div>
    </article>
</template>

<script lang="ts" setup>
import { type PropType } from 'vue'
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'
import type { Models } from '~/types/models'

import type { Api } from '~~/global'

const props = defineProps({
    cta: {
        type: Object as PropType<Models.CtaCard>,
        required: true,
    },
})
const { setSiteNotification } = useRootStore()
const { $endpoints, $assets, $lang } = useNuxtApp()
const hide = ref(false)
const subscribing = ref(false)
const form = reactive({
    data: {
        email: '' as string,
        agree: false as boolean,
    },
    error: {
        email: '' as string,
        agree: false as boolean,
    },
})

const image = $assets.illustration.cta.email
const image2 = '/assets/png/bg-email.png'

useHead({
    link: [
        {
            rel: 'preload',
            as: 'image',
            href: image,
            media: !(props.cta.mobile && props.cta.desktop)
                ? props.cta.mobile
                    ? '(max-width: 1023px)'
                    : props.cta.desktop
                      ? '(min-width: 1024px)'
                      : ''
                : '',
        },
        {
            rel: 'preload',
            as: 'image',
            href: image2,
            media: !(props.cta.mobile && props.cta.desktop)
                ? props.cta.mobile
                    ? '(max-width: 1023px)'
                    : props.cta.desktop
                      ? '(min-width: 1024px)'
                      : ''
                : '',
        },
    ],
})
const AuthStore = useAuthStore()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const newSubscription = async () => {
    if (!subscribing.value) {
        if (form.data.agree === false) {
            form.error.agree = true
        } else {
            form.error.agree = false
        }
        if (form.data.email.length === 0) {
            form.error.email = $lang.components.ctaEMail.insertEmail
        } else {
            form.error.email = ''
        }
        if (form.error.agree || form.error.email.length > 0) {
            return
        }

        try {
            subscribing.value = true

            const result = await $fetch<Api.Responses.General.NewsletterSubscription>(
                endpoints.general.newsletter.subscribe,
                {
                    headers: buildHeaders(),
                    method: 'POST',
                    onResponse() {
                        AuthStore.updateActivitiesCount('notifications')
                    },
                    body: form.data,
                },
            )

            if (result.feedback === 'data_success') {
                form.data.email = ''
                form.error.email = ''
                form.data.agree = false
                form.error.agree = false
                setSiteNotification({
                    text: result.data.message || $lang.components.ctaEMail.success,
                    type: 'success',
                })
            } else if (result.feedback === 'existing_email') {
                setSiteNotification({
                    text: result.data.message || $lang.components.ctaEMail.failed,
                    type: 'error',
                })
            } else if (result.feedback === 'params_validation_failed') {
                form.error.email = result.data.email[0] || $lang.components.ctaEMail.error
            } else if (result.feedback === 'error_mail') {
                form.error.email = result.data.message || $lang.components.ctaEMail.error
            }

            subscribing.value = false
        } catch (e) {}
    }
}
</script>
