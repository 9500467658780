<template>
    <article
        v-if="!hide"
        class="card-cta-whatsapp"
        :style="{ backgroundImage: `url(${$assets.illustration.cta.whatsapp.bg})` }"
    >
        <img :src="$assets.illustration.cta.whatsapp.tag" alt="tag" class="card-cta-whatsapp__tag" />
        <a
            :href="$lang.components.ctaSocial.url.whatsapp"
            target="_blank"
            rel="nofollow"
            class="card-cta-whatsapp__logo"
        >
            <img :src="$assets.illustration.cta.whatsapp.logo" alt="whatsapp" width="154" height="145" />
        </a>

        <div class="card-cta-whatsapp__notifications">
            <div type="button" class="card-cta-whatsapp__notification-item">
                <img :src="$assets.gray.bell" alt="icon" />
                <p>{{ $lang.components.ctaSocial.notification }}</p>
            </div>
            <button type="button" class="card-cta-whatsapp__close" @click="() => (hide = true)">
                <img :src="$assets.gray.close" alt="icon" />
            </button>
        </div>

        <a class="card-cta-whatsapp__title" :href="$lang.components.ctaSocial.url.whatsapp" rel="nofollow">
            <h3>
                {{ $lang.components.ctaSocial.notification_text }}
                <b
                    ><u>{{ $lang.components.ctaSocial.notification_text_2.whatsapp }}</u></b
                >
            </h3>
        </a>

        <div class="card-cta-whatsapp__team">
            <picture>
                <img :src="$assets.brand.favicon" alt="icon" />
            </picture>
            <p>{{ $lang.components.ctaSocial.team }}</p>
        </div>

        <div class="card-cta-whatsapp__button">
            <a :href="$lang.components.ctaSocial.url.whatsapp" target="_blank" rel="nofollow">{{
                $lang.components.ctaSocial.accept
            }}</a>
        </div>
    </article>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'CardCtaWhatsapp',
    props: {
        cta: {
            type: Object as PropType<Models.CtaCard>,
            required: true,
        },
    },
    data() {
        return {
            hide: false,
            bg: this.$assets.illustration.cta.whatsapp.bg,
        }
    },
    head() {
        const image1 = this.$assets.illustration.cta.whatsapp.logo
        const image2 = this.$assets.illustration.cta.whatsapp.bg
        return {
            link: [
                {
                    rel: 'preload',
                    as: 'image',
                    href: image1,
                    media: !(this.cta.mobile && this.cta.desktop)
                        ? this.cta.mobile
                            ? '(max-width: 1023px)'
                            : this.cta.desktop
                              ? '(min-width: 1024px)'
                              : ''
                        : '',
                },
                {
                    rel: 'preload',
                    as: 'image',
                    href: image2,
                    media: !(this.cta.mobile && this.cta.desktop)
                        ? this.cta.mobile
                            ? '(max-width: 1023px)'
                            : this.cta.desktop
                              ? '(min-width: 1024px)'
                              : ''
                        : '',
                },
            ],
        }
    },
})
</script>

<style lang="postcss" scoped></style>
