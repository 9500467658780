<template>
    <div v-if="!expired" class="cta-handler-scope" :class="viewportClasses">
        <div v-if="cta.template === 'extension'">
            <CTAExtAndApp mode="ext" :cta="cta" />
        </div>
        <div v-if="cta.template === 'app'">
            <CTAExtAndApp mode="app" :cta="cta" />
        </div>
        <div v-if="cta.template === 'email'">
            <CTAEmail :cta="cta" />
        </div>
        <div v-if="cta.template === 'telegram'">
            <CTATelegram :cta="cta" />
        </div>
        <div v-if="cta.template === 'facebook'">
            <CTAFacebook :cta="cta" />
        </div>
        <div v-if="cta.template === 'whatsapp'">
            <CTAWhatsapp :cta="cta" />
        </div>
    </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'CtaHandler',
    props: {
        cta: {
            type: Object as PropType<Models.CtaCard>,
            required: true,
        },
    },
    data() {
        return {
            expired: this.cta.expired_at ? new Date() > new Date(this.cta.expired_at) : false,
        }
    },
    computed: {
        viewportClasses(): any {
            return {
                block: this.cta.mobile && this.cta.desktop,
                'block lg:hidden': !(this.cta.mobile && this.cta.desktop) && this.cta.mobile,
                'hidden lg:block': !(this.cta.mobile && this.cta.desktop) && this.cta.desktop,
                hidden: !this.cta.mobile && !this.cta.desktop,
            }
        },
    },
})
</script>

<style lang="postcss">
.cta-handler-scope {
    .card-cta-telegram {
        @apply relative grid grid-cols-12 gap-2 rounded-lg border border-gray-200 bg-white p-2 lg:gap-3;
        &__tag {
            @apply absolute -left-3.5 top-3;
        }
        &__logo {
            @apply col-span-4 row-span-3 mx-auto block self-center sm:col-span-3 lg:col-span-2;
            img {
                @apply rounded-lg;
            }
        }
        &__notifications {
            @apply col-span-8 flex justify-end space-x-3 sm:col-span-9 lg:col-span-10;
        }
        &__notification-item {
            @apply flex h-6 items-center space-x-1.5 rounded-full bg-white px-2 py-1 text-xs shadow;
            img {
                @apply h-4 w-4;
            }
        }
        &__close {
            @apply flex h-6 w-6 items-center justify-center;
            img {
                @apply h-3 w-3;
            }
        }
        &__title {
            @apply col-span-8 sm:col-span-9 lg:col-span-10;

            h3 {
                @apply mb-0 text-base leading-5 sm:text-lg !important;
            }
        }
        &__team {
            @apply col-span-8 flex items-center space-x-1.5 self-center text-xs font-semibold sm:col-span-9 lg:col-span-6;
            picture {
                @apply block h-8 w-8 flex-none;
                img {
                    @apply h-full w-full object-contain;
                }
            }
        }
        &__button {
            @apply col-span-12 lg:col-span-4;
            a {
                @apply flex h-12 w-full items-center justify-center rounded-lg bg-[#007AAE] font-semibold text-white  lg:ml-auto lg:max-w-[224px]; /* border-2 text-[#007AAE] */
            }
        }
    }
    .card-cta-facebook {
        @apply relative grid grid-cols-12 gap-2 rounded-lg border border-gray-200 bg-white p-2 lg:gap-3;
        &__tag {
            @apply absolute -left-3.5 top-3;
        }
        &__logo {
            @apply col-span-4 row-span-3 mx-auto block self-center sm:col-span-3 lg:col-span-2;
            img {
                @apply rounded-lg;
            }
        }
        &__notifications {
            @apply col-span-8 flex justify-end space-x-3 sm:col-span-9 lg:col-span-10;
        }
        &__notification-item {
            @apply flex h-6 items-center space-x-1.5 rounded-full bg-white px-2 py-1 text-xs shadow;
            img {
                @apply h-4 w-4;
            }
        }
        &__close {
            @apply flex h-6 w-6 items-center justify-center;
            img {
                @apply h-3 w-3;
            }
        }
        &__title {
            @apply col-span-8 sm:col-span-9 lg:col-span-10;

            h3 {
                @apply mb-0 text-base leading-5 sm:text-lg md:text-xl !important;
            }
        }
        &__team {
            @apply col-span-8 flex items-center space-x-1.5 self-center text-xs font-semibold sm:col-span-9 lg:col-span-6;
            picture {
                @apply block h-8 w-8 flex-none;
                img {
                    @apply h-full w-full object-contain;
                }
            }
        }
        &__button {
            @apply col-span-12 lg:col-span-4;
            a {
                @apply flex h-12 w-full items-center justify-center  rounded-lg bg-[#446AE0] font-semibold text-white lg:ml-auto lg:max-w-[224px];
            }
        }
    }
    .card-cta-whatsapp {
        @apply relative grid grid-cols-12 gap-2 rounded-lg border border-gray-200 bg-white bg-contain bg-right bg-no-repeat p-2 lg:gap-3;

        @media (max-width: theme('screens.lg')) {
            @apply bg-none !important;
        }
        &__tag {
            @apply absolute -left-3.5 top-3;
        }
        &__logo {
            @apply col-span-4 row-span-3 mx-auto block self-center sm:col-span-3 lg:col-span-2;
        }
        &__notifications {
            @apply col-span-8 flex justify-end space-x-3 sm:col-span-9 lg:col-span-10;
        }
        &__notification-item {
            @apply flex h-6 items-center space-x-1.5 rounded-full bg-white px-2 py-1 text-xs shadow;
            img {
                @apply h-4 w-4;
            }
        }
        &__close {
            @apply flex h-6 w-6 items-center justify-center;
            img {
                @apply h-3 w-3;
            }
        }
        &__title {
            @apply col-span-8 sm:col-span-9 lg:col-span-10;

            h3 {
                @apply mb-0 text-base leading-5 sm:text-lg md:text-xl !important;
            }
        }
        &__team {
            @apply col-span-8 flex items-center space-x-1.5 self-center text-xs font-semibold sm:col-span-9 lg:col-span-6;
            picture {
                @apply block h-8 w-8 flex-none;
                img {
                    @apply h-full w-full object-contain;
                }
            }
        }
        &__button {
            @apply col-span-12 lg:col-span-4;
            a {
                @apply flex h-12 w-full items-center justify-center rounded-lg bg-[#44B349] font-semibold text-white lg:ml-auto lg:max-w-[224px];
            }
        }
    }
    .cta-email-card {
        @apply flex w-full items-center justify-center gap-x-2 rounded-xl bg-cover bg-no-repeat px-3 py-2.5 lg:gap-x-8;
        &__image {
            @apply hidden h-[170px] w-[170px] flex-none items-center justify-center sm:block;
            img {
                @apply h-full w-full object-contain pl-1 lg:pl-0;
            }
        }
        &__info {
            @apply flex flex-col justify-center space-y-1 py-2 text-white lg:space-y-2 lg:py-0;
            & .cta-email-info {
                &__title {
                    @apply text-xl font-semibold text-black lg:text-2xl;
                }
                &__subtitle {
                    @apply text-sm font-semibold text-site-primary lg:text-base;
                }
                &__email {
                    @apply space-y-3;
                    &-form {
                        @apply flex gap-2 pt-4;
                        &-input {
                            @apply w-full;
                        }
                        &-btn {
                            @apply bg-site-primary py-[22px] text-sm md:text-base;
                        }
                    }
                    &-checkbox {
                        &-label {
                            @apply text-sm lg:text-base;
                        }
                    }
                }
            }
        }
    }
    .ext-and-app {
        @apply flex w-full justify-center gap-x-2 rounded-xl bg-cover bg-no-repeat lg:gap-x-8;
        &__image {
            @apply flex w-1/2 items-center justify-center md:w-52;
            img {
                @apply pl-3 lg:pl-0;
            }
        }
        &__info {
            @apply flex flex-col justify-center space-y-0 p-3 text-white lg:space-y-1 lg:p-4;
            & .info {
                &__head {
                    @apply text-sm font-semibold sm:text-2xl;
                }
                &__title {
                    @apply flex items-center gap-x-1 text-base font-semibold sm:text-2xl lg:gap-x-2 lg:text-4xl;
                    img {
                        @apply w-16 sm:w-auto;
                    }
                }
                &__description {
                    @apply text-xs sm:text-sm lg:text-xl;
                }
                &__stores {
                    @apply flex gap-x-1.5 pt-2 lg:gap-x-3 lg:pt-3;
                    &-app {
                        @apply w-[45%] overflow-hidden rounded sm:w-auto;
                        img {
                            @apply h-full w-full object-cover;
                        }
                    }
                    &-ext {
                        @apply w-[70%] sm:w-auto;
                    }
                }
            }
        }
    }
}
</style>
