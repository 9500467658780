<template>
    <section class="ext-and-app" :style="{ backgroundImage: `url('/assets/svg/bg-extension.svg')` }">
        <div class="ext-and-app__info">
            <p class="info__head">{{ text.head }}</p>
            <h3 class="info__title">
                {{ text.title }}
                <img :src="$assets.illustration.cta.fiveStars" :alt="mode === 'ext' ? 'extension' : 'app'" />
            </h3>
            <p v-if="text.description" class="info__description">{{ text.description }}</p>
            <div class="info__stores">
                <a
                    v-if="mode === 'ext'"
                    class="info__stores-ext"
                    href="https://chrome.google.com/webstore/detail/megadescuentos/gkochegofmljpmgegphbcljdigenjlhd?hl=es&authuser=5"
                    target="_blank"
                >
                    <img :src="$assets.illustration.cta.googlechrome" alt="Google Chrome" />
                </a>
                <NuxtLink no-prefetch v-if="mode === 'app'" class="info__stores-app" to="/app">
                    <img :src="$assets.illustration.cta.googleplay" alt="Google Play" />
                </NuxtLink>
                <NuxtLink no-prefetch v-if="mode === 'app'" class="info__stores-app" to="/app">
                    <img :src="$assets.illustration.cta.appleStore" alt="App Store" />
                </NuxtLink>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'CTAExtAndApp',
    props: {
        cta: {
            type: Object as PropType<Models.CtaCard>,
            required: true,
        },
        mode: {
            type: String as PropType<'ext' | 'app'>,
            default: 'ext',
        },
    },
    data() {
        return {
            hide: false,
            extHead: this.$lang.components.ctaExtAndApp.extHead,
            extTitle: this.$lang.components.ctaExtAndApp.extTitle,
            extDescription: this.$lang.components.ctaExtAndApp.extDescription,
            appHead: this.$lang.components.ctaExtAndApp.appHead,
            appTitle: this.$lang.components.ctaExtAndApp.appTitle,
        }
    },
    head() {
        const image =
            this.mode === 'ext' ? this.$assets.illustration.cta.extension : this.$assets.illustration.cta.app
        const image2 = '/assets/svg/bg-extension.svg'
        return {
            link: [
                {
                    rel: 'preload',
                    as: 'image',
                    href: image,
                    media: !(this.cta.mobile && this.cta.desktop)
                        ? this.cta.mobile
                            ? '(max-width: 1023px)'
                            : this.cta.desktop
                              ? '(min-width: 1024px)'
                              : ''
                        : '',
                },
                {
                    rel: 'preload',
                    as: 'image',
                    href: image2,
                    media: !(this.cta.mobile && this.cta.desktop)
                        ? this.cta.mobile
                            ? '(max-width: 1023px)'
                            : this.cta.desktop
                              ? '(min-width: 1024px)'
                              : ''
                        : '',
                },
            ],
        }
    },

    computed: {
        text(): { head: string; title: string; description?: string } {
            if (this.mode === 'ext') {
                return {
                    head: this.extHead,
                    title: this.extTitle,
                    description: this.extDescription,
                }
            } else {
                return {
                    head: this.appHead,
                    title: this.appTitle,
                }
            }
        },
        imageWidth(): number {
            return this.mode === 'ext' ? 52 : 48
        },
    },
    methods: {
        hideCard() {
            this.hide = true
        },
    },
})
</script>
