<template>
    <article v-if="!hide" class="card-cta-telegram">
        <img :src="$assets.social.telegramTag" alt="tag" class="card-cta-telegram__tag" />

        <a
            :href="$lang.components.ctaSocial.url.telegram"
            target="_blank"
            rel="nofollow"
            class="card-cta-telegram__logo"
        >
            <img :src="$assets.social.telegramFullImage" alt="telegram" width="154" height="145" />
        </a>

        <div class="card-cta-telegram__notifications">
            <div type="button" class="card-cta-telegram__notification-item">
                <img :src="$assets.gray.bell" alt="icon" />
                <p>{{ $lang.components.ctaSocial.notification }}</p>
            </div>
            <button type="button" class="card-cta-telegram__close" @click="() => (hide = true)">
                <img :src="$assets.gray.close" alt="icon" />
            </button>
        </div>

        <a class="card-cta-telegram__title" :href="$lang.components.ctaSocial.url.telegram" rel="nofollow">
            <h3>
                {{ $lang.components.ctaSocial.notification_text }}
                <b
                    ><u>{{ $lang.components.ctaSocial.notification_text_2.telegram }}</u></b
                >
            </h3>
        </a>

        <div class="card-cta-telegram__team">
            <picture>
                <img :src="$assets.brand.favicon" alt="icon" />
            </picture>
            <p>{{ $lang.components.ctaSocial.team }}</p>
        </div>

        <div class="card-cta-telegram__button">
            <a :href="$lang.components.ctaSocial.url.telegram" target="_blank" rel="nofollow">{{
                $lang.components.ctaSocial.accept
            }}</a>
        </div>
    </article>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'CardCtaTelegram',
    props: {
        cta: {
            type: Object as PropType<Models.CtaCard>,
            required: true,
        },
    },
    data() {
        return {
            hide: false,
        }
    },
    head() {
        const image = this.$assets.social.telegramFullImage
        return {
            link: [
                {
                    rel: 'preload',
                    as: 'image',
                    href: image,
                    media: !(this.cta.mobile && this.cta.desktop)
                        ? this.cta.mobile
                            ? '(max-width: 1023px)'
                            : this.cta.desktop
                              ? '(min-width: 1024px)'
                              : ''
                        : '',
                },
            ],
        }
    },
})
</script>
